@import 'var';

// Global Classes
.noscroll {
    height: 100vh;
    overflow: hidden; }

// Skillbar
.skillbars {
    position: relative;
    width: 100%;

    @media screen and (min-width: $mq-min-width-large) {
        width: 80%; }

    .skillbar {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        margin: 0 0 1rem 0;

        @media screen and ( min-width:  $mq-min-width-large ) {
            flex-direction: row; }


        &__title {
            position: relative;
            width: 100%;
            @media screen and ( min-width:  $mq-min-width-large ) {
                width: 20%; } }

        &__show {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            min-height: 2rem;
            border: 1px solid $prime;
            border-radius: 1rem;
            margin: 0 0 0 0;
            overflow: hidden;

            span {
                margin: 0 2rem 0 0;
                color: $white; }

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                background-color: $prime;
                transform-origin: left;
                transition: transform 1000ms ease 500ms; }

            &.html {
                &::after {
                    transform: scaleX(0.8); } }
            &.css {
                &::after {
                    transform: scaleX(0.7); } }
            &.js {
                &::after {
                    transform: scaleX(0.5); } }
            &.node {
                &::after {
                    transform: scaleX(0.3); } }
            &.vue {
                &::after {
                    transform: scaleX(0.3); } }
            &.unix {
                &::after {
                    transform: scaleX(0.8); } }
            &.ps {
                &::after {
                    transform: scaleX(0.3); } }
            &.ai {
                &::after {
                    transform: scaleX(0.4); } }
            &.pp {
                &::after {
                    transform: scaleX(0.5); } }
            &.ae {
                &::after {
                    transform: scaleX(0.4); } }

            &.start {
                &::after {
                    transform: scaleX(0); } } } } }





.contentWrapper {
    width: 100vw;
    height: 100vh;

    .background {
        position: fixed;
        height: 5rem;
        width: 5rem;
        // border-radius: 2.5rem
        background-color: white;
        top: -5rem;
        right: calc(50% - 2.5rem);
        box-shadow: 0 0 1rem 2.5rem $background,0 0 1rem 5rem $background, 0 0 1rem 7.5rem $background, 0 0 1rem 10rem $background;


        @media (min-width: $mq-min-width) {
            top: 50%;
            right: -10rem;
            // box-shadow: 0 0 1rem 20rem $background, 0 0 1rem 30rem $background, 0 0 1rem 40rem $background, 0 0 1rem 50rem $background, 0 0 1rem 60rem $background, 0 0 1rem 70rem $background, 0 0 1rem 80rem $background
            // box-shadow: 0 0 1rem 20rem $background, 0 0 1rem 30rem $background, 0 0 1rem 40rem $background, 0 0 1rem 50rem $background, 0 0 1rem 60rem $background
            box-shadow: 0 0 1rem 20rem $background, 0 0 1rem 30rem $background, 0 0 1rem 40rem $background, 0 0 1rem 50rem $background, 0 0 1rem 60rem $background; } }


    section {
        width: 100vw;
        height: 100vh;
        // background: linear-gradient(180deg, lighten($second, 20%), 30%, $second)
        background: linear-gradient(180deg, $gradientOne, $gradientTwo);
        display: flex;
        flex-direction: column-reverse;
        scroll-snap-align: start;
        overflow: hidden;
        position: relative;


        @media (min-width: $mq-min-width) {
            flex-direction: row;
            // background: linear-gradient(-90deg, lighten($second, 20%), 20%, $second)
            background: linear-gradient(-90deg, $gradientOne, $gradientTwo);
 }            // background: linear-gradient(180deg, lighten($second, 20%), 30%, $second)

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 4rem 2rem 4rem 2rem;
            position: relative;
            transition: transform 600ms ease 300ms, opacity 800ms ease 300ms;
            transform: translate(-150%, 0);
            opacity: 0;

            @media (min-width: $mq-min-width) {
                justify-content: center;
                align-items: flex-start; }

            &.appear {
                transform: translate(0);
                opacity: 1;
                z-index: 10; }


            &__title {
                position: relative;
                height: 1rem;
                z-index: 5;
                display: flex;
                justify-content: center;

                @media (min-width: $mq-min-width) {
                    // transform: translateX(25%)
                    margin: 0 0 0 25%;
                    width: 100%;
                    flex-warp: nowarp;
                    height: auto; }

                h2 {
                    margin: 0 0 0 0;
                    padding: 0 0 0 0;

                    font-size: 5rem;
                    line-height: 5rem;
                    font-weight: 600;
                    letter-spacing: 0.25rem;
                    color: white;
                    transform: translate(0rem, -7rem);
                    @media (min-width: $mq-min-width) {
                        width: 100%;
                        font-size: 16rem;
                        line-height: 15rem; } } }

            &__text {

                @media (min-width: $mq-min-width) {
                    margin: 0 0 0 25%; }

                h5 {
                    color: white;
                    font-size: 1.5rem;
                    margin: 0 0 2rem 0;
                    line-height: 1.5rem;
                    margin: 0 0 0 0;
                    letter-spacing: 0.25rem;
                    @media (min-width: $mq-min-width) {
                        font-size: 3rem;
                        line-height: 3rem; } }

                p {
                    color: white;
                    font-size: 1.4rem;
                    letter-spacing: 0.25rem;
                    margin: 0; } }

            &__button {
                position: relative;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;

                @media (min-width: $mq-min-width) {
                    justify-content: flex-start;
                    margin: 4rem 0 0 25%; }

                i {
                    margin: 0 2rem 0 0; } }

            button {
                border: 0;
                color: white;
                // background-color: lighten($second,0%)
                background-color: $prime;
                border-radius: 2rem;
                padding: 1rem 2rem 1rem 2rem;
                font-size: 1.6rem;
                cursor: pointer;
                @media (min-width: $mq-min-width) {
                    padding: 1.5rem 3rem 1.5rem 3rem;
                    font-size: 1.7rem;
                    font-weight: 300;
                    border-radius: 3rem; } } }


        .details {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: white;
            z-index: 10;
            transform: scaleY(0);
            transition: all 500ms ease;
            padding: 2rem 2rem 2rem 2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: scroll;

            @media screen and ( min-width: $mq-min-width ) {
                padding: 5rem 5rem 5rem 15rem; }


            h5,h6 {
                color: white;
                font-size: 1.5rem;
                margin: 0 0 2rem 0;
                line-height: 1.5rem;
                margin: 2rem 0 3rem 0;
                letter-spacing: 0.25rem; }

            h5 {
                font-size: 2rem;
                line-height: 2rem;
                @media (min-width: $mq-min-width) {
                    font-size: 3rem;
                    line-height: 3rem; } }

            h6 {
                @media screen and ( min-width: $mq-min-width  ) {
                    font-size: 2.5rem;
                    line-height: 2.5rem; } }

            p, li {
                color: white;
                font-size: 1.4rem;
                letter-spacing: 0.25rem;
                // margin: 0.8rem 0 0 0
                margin: 0;

                @media screen and ( min-width: $mq-min-width  ) {
                    font-size: 1.8rem; } }

            p {
                margin: 1.4rem 0 0 0; }

            p:first-child {
                margin: 0 0 0 0; }




            a {
                text-decoration: none;
                color: $prime;
                font-weight: 600;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    min-height: 0.3rem;
                    background-color: $prime;
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: transform 250ms ease; }

                &:hover,&:focus {
                    &::after {
                        transform: scaleX(1); } } }



            &--show {
                transform: scaleY(1);
                background-color: transparent; }

            &__close {
                position: sticky;
                top: 0rem;
                right: 0;
                min-height: 4rem;
                width: 100%;
                margin: 0rem 0rem 0rem 0rem;
                cursor: pointer;
                transition: transform 250ms ease;
                z-index: 2;

                @media screen and ( min-width: $mq-min-width  ) {
                    margin: 0 4rem 0 0; }


                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 1.5rem;
                    right: 0rem;
                    min-width: 3rem;
                    min-height: 0.2rem;
                    background-color: white;
                    transform: translate(0, 0%) rotate(45deg);
                    transition: all 250ms ease; }

                &::after {
                    transform: rotate(-45deg); }

                &:hover {
                    &::before {
                        transform: rotate(135deg); }
                    &::after {
                        transform: rotate(45deg); } } }


            &__text, &__title, &__skills, &__cv, &__overview {
                max-width: 100%;

                @media screen and ( min-width: $mq-min-width ) {
                    max-width: 90%; } }

            &__title {
                width: 100%; }

            &__text {
                padding: 0rem 0 4rem 0; }

            &__overview, &__skills, &__cv, &__work {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                padding: 2rem 0 4rem 0;


                @media (min-width: $mq-min-width) {
                    // min-height: 50vh
                    // scroll-snap-align: start
                    padding: 4rem 0 4rem 0; } }

            &__skills, &__work {
                @media (min-width: $mq-min-width) {
                    min-height: 50vh;
                    justify-content: flex-start; } }


            .workitems {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
                gap: 5rem;
                width: 100%; }


            .workitem {
                position: relative;

                &__image {
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    &::before {
                        content: '';
                        color: $white;
                        position: absolute;
                        inset: 0;
                        z-index: 1;
                        background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7));
                        transition: transform 250ms ease;
                        transform: translateY(0); }


                    &:hover {
                        &::before {
                            transform: translateY(100%); } }


                    img {
                        position: relative;
                        width: 100%;
                        height: auto;
                        object-fit: cover; } }

                &__description {
                    span {
                        font-size: 1.2rem;
                        font-weight: 400; } } } }





        .image {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 5vh;
            perspective: 500px;
            transition: transform 500ms ease 700ms;

            &--details {
                transform: translate(200%, 0%) scale(0.5); }

            @media (min-width: $mq-min-width) {
                margin-top: 0; } }




        .background__image {
            width: 90vw;
            height: 55vh;
            position: relative;
            transform: rotateY(-5deg);
            overflow: hidden;
            z-index: 2;
            box-shadow: 1rem 0.5rem 0.5rem rgba(0,0,0,0.1);
            transition: transform 500ms ease;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.9)); }

            @media (min-width: $mq-min-width) {
                width: 40vw;
                height: 40vw;
                // border-radius: 25vw
                transform: rotateY(0deg); }

            picture {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover; } } }


        .background__overlay {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $second;
            transform: translateX(0%);
            transition: transform 800ms ease;
            z-index: 10;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -20rem;
                bottom: 0;
                width: 20rem;
                transform: scale(1);
                background-color: $sliderBlock;
                // background-color: darken($second, 20%)
                transition: transform 500ms ease 250ms; } }


        & .appear {
            .background__image {
                transform: rotateY(-5deg) scale(1);

                @media (min-width: $mq-min-width) {
                    transform: rotateY(0deg) scale(1); } }


            .background__overlay {
                transform: translateX(100%);
                &::before {
                    transform: translateX(20rem) scale(1); } } } } }




// Timeline

.timeline {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    &__header {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        min-width: 6rem;
        height: 3rem;
        background-color: $white;
        color: $second; }

    &__content {
        padding: 2rem 0 0 3rem;
        font-size: 1rem;

        p {

            @media screen and ( min-width: $mq-min-width  ) {
                font-size: 1.2rem; } } }



    &__item {
        align-self: flex-end;
        position: relative;
        width: 50%;
        padding: 0rem 0 2rem 0;


        &::before {
            content: '';
            display: block;
            width: 0.1rem;
            height: 100%;
            background-color: $white;
            position: absolute;
            top: 0;
            right: 100%; }

        &::after {
            content: '';
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 1.5rem;
            background-color: $white;
            top: 3rem;
            position: absolute;
            transform: translate(-50%,-50%); }

        &:nth-of-type(even) {
            align-self: flex-start;
            text-align: right;

            &::before {
                right: 0; }

            &::after {
                right: 0;
                transform: translate(50%,-50%); }

            & div {
                padding: 2rem 3rem 0 0; } } } }


.title, .subtitle {
    font-size: 1.4rem;
    margin: 1rem 0 0rem 0;
    padding: 0 0 0 0;
    color: $white;
    font-weight: 800; }

.subtitle {
    font-weight: 400; }
.date {
    font-size: 1rem;
    padding: 0 0 0 0;
    font-weight: 300; }































