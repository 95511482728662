// ###############################################
// ###############################################
// START Boilerplate (not Important for the Tutorial)
$prime: rgba(129, 221, 229, 0.5);
$second: rgb(12, 16, 22);
$white: rgb(255, 255, 255);


// $prime: rgb(213,152,171)
// $second: #edf6f9
$background: rgba(129,221,229,0.2);
// $background: rgba(255,255,255,0.1)
// $sliderBlock: rgba(129,221,229,1)
$sliderBlock: $prime;
// $gradientOne: rgba(155,161,166,255)
// $gradientTwo: rgba(232,229,228,255)
$gradientOne: lighten(rgba(12, 16, 22,1), 20%);
$gradientTwo: rgba(12, 16, 22,1);

$mq-min-width: 760px;
$mq-min-width-large: 1200px;

body,
html {
    align-items: center;
    background-color: $second;
    display: flex;
    font-family: 'Nunito Sans';
    font-size: 10px;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    scroll-snap-type: y mandatory; }

* {
    box-sizing: border-box; }
